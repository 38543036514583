export class CartFieldsHandler {
    constructor() {
        // Get references to the relevant elements
        this.pickupContainer = document.querySelector('.cart__top_pickup_container');
        this.pickupClient = document.querySelector('.cart__top_pickup_client');
        this.pickupCourier = document.querySelector('.cart__top_pickup_courier');
        this.deliveryFloorField = document.getElementById('delivery_floor_field');
        this.packageMovingFloors = document.querySelector('.package-moving-floors');
        this.shippingSpan = document.querySelector('.shipping_price');

        this.shippingSpan.style.display = 'none';

        // Add event listeners for change events
        document.getElementById('billing_city').addEventListener('change', () => this.handleFormChange());
        document.getElementById('shipping_street').addEventListener('change', () => this.handleFormChange());
        document.getElementById('shipping_building_number').addEventListener('change', () => this.handleFormChange());
        document.getElementById('shipping_apartment_number').addEventListener('change', () => this.handleFormChange());

        const packageMovingFloor = document.getElementById('package_moving_floor');
        if (packageMovingFloor) {
            packageMovingFloor.addEventListener('change', (event) => {
                event.preventDefault();
                this.makeAjaxCall();
                this.handleFormChange();
                this.updatePricesBasedOnQuantity();
            });
        }

        document.getElementById('shipping_option').addEventListener('change', (event) => {
             event.preventDefault();
            this.updateFieldsVisibility();
            this.handleFormChange();
            this.makeAjaxCall();
            this.checkTotalCartValueAfter();
        });

        document.getElementById('delivery_floor').addEventListener('change', (event) => {
             event.preventDefault();
            this.updateFieldsVisibility();
            this.handleFormChange();
            this.makeAjaxCall();
            this.checkTotalCartValueAfter();
        });

        this.initRemoveButtons();
        this.initQuantityUpdates();
        this.checkTotalCartValue();
        this.updateFieldsVisibility();
        this.updatePricesBasedOnQuantity();
        this.handleFormChange();
        this.checkTotalCartValueAfter();
    }


    initRemoveButtons() {
        document.querySelectorAll('.remove-from-cart').forEach(button => button.addEventListener('click', (event) => this.handleRemoveButtonClick(event, button)));
    }

    initQuantityUpdates() {
        document.addEventListener('click', (event) => {
            const target = event.target;
            const isQuantityButton = target.matches('.cart__item_quantity_subtract, .cart__item_quantity_add');

            if (isQuantityButton) {
                event.preventDefault();

                const cartItemElement = target.closest('.cart__item');
                const quantityInput = cartItemElement.querySelector('.cart__quantity_input');
                const productId = cartItemElement.getAttribute('data-cart-item-id');

                let currentQuantity = parseInt(quantityInput.value);
                currentQuantity = Math.max(0, currentQuantity + (target.classList.contains('cart__item_quantity_subtract') ? -1 : 1));

                quantityInput.value = currentQuantity;

                if (currentQuantity === 0) {
                    this.removeFromCart(productId);
                } else {
                    this.updateCartItem(productId, currentQuantity);
                    window.location.reload();
                }

                // Update prices when quantity changes
                this.updatePricesBasedOnQuantity();
            }
        });
    }


    updatePricesBasedOnQuantity() {
        const selectedFloor = document.getElementById('package_moving_floor')?.value ?? '';
        const loadingDiv = document.querySelector('#loading');

        // Show loading spinner
        loadingDiv.classList.add('loading', 'spinner');

        // Make an AJAX request to get the updated price
        fetch(ajax.ajax_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                action: 'update_prices',
                selected_floor: selectedFloor,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json(); // Parse the response as JSON
            })
            .then(data => {
                // Hide loading spinner
                loadingDiv.classList.remove('loading', 'spinner');

                // Update the content with the received data
                const packageMovingFloors = document.querySelector('#package_moving_floor');
                if (packageMovingFloors) {
                    packageMovingFloors.innerHTML = data.data;
                } else {
                    console.error('Error: package-moving-floors element not found.');
                }
            })
            .catch(error => {
                // Hide loading spinner in case of an error
                loadingDiv.classList.remove('loading', 'spinner');
                console.error('Error:', error);
            });
    }


    handleRemoveButtonClick(event, button) {
        event.preventDefault();

        const productId = button.getAttribute('data-product-id');

        // Make an AJAX request to remove the item from the cart
        fetch(ajax.ajax_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `action=remove_from_cart&security=${ajax.ajax_nonce}&product_id=${encodeURIComponent(productId)}`,
        })
            .then(response => response.json())
            .then(data => {

                if (data.success) {
                    // Item removed successfully, update the UI or take any necessary actions
                    window.location.reload();
                } else {
                    console.error('Failed to remove item from the cart.');
                }
            })
            .catch(error => {
                console.error('Error during the removal request:', error);
            });
    }

    updateCartItem(productId, quantity) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', ajax.ajax_url, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                const response = JSON.parse(xhr.responseText);
                if (response.success) {
                    const cartItemElement = document.querySelector(`[data-cart-item-id="${response.data.product_id}"]`);

                    if (cartItemElement) {
                        const itemPriceTimesQtyElement = cartItemElement.querySelector('.cart__item_total span');
                        const totalCartPriceElement = document.querySelector('.cart__totals_sub dd');
                        const totalCartTaxElement = document.querySelector('.cart__totals_tax dd');

                        if (itemPriceTimesQtyElement && totalCartPriceElement && totalCartTaxElement) {
                            itemPriceTimesQtyElement.innerHTML = response.data.item_price_times_qty;
                            totalCartPriceElement.innerHTML = response.data.total_cart_price;
                            totalCartTaxElement.innerHTML = response.data.total_tax;
                        }
                    }
                }
            } else {
                console.error(xhr.statusText);
            }
        };

        xhr.onerror = () => {
            console.error('Network error occurred');
        };

        const data = `action=update_cart_item&security=${ajax.ajax_nonce}&product_id=${encodeURIComponent(productId)}&quantity=${encodeURIComponent(quantity)}`;
        xhr.send(data);
    }

    checkTotalCartValue() {
        // Check if the total cart value is already in the localStorage
        const cachedCartValue = localStorage.getItem('totalCartValue');

        // If the cached value exists, update the UI with the cached value
        const totalCartPriceElement = document.querySelector('.cart__totals_sub dd');
        if (cachedCartValue !== null && totalCartPriceElement) {
            totalCartPriceElement.innerHTML = cachedCartValue;
        }

        // Create a new XMLHttpRequest object
        const xhr = new XMLHttpRequest();

        // Define the request type, URL, and set it to be asynchronous
        xhr.open('POST', ajax.ajax_url, true);

        // Set the request header for POST requests
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        // Set up a callback function to handle the response
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                // The request was successful
                const response = JSON.parse(xhr.responseText);

                // Handle success, e.g., update the cart totals
                if (response.success) {
                    // Find the corresponding cart item element using product_id
                    const totalCartPriceElement = document.querySelector('.cart__totals_sub dd');

                    if (totalCartPriceElement) {
                        totalCartPriceElement.innerHTML = response.data.total_cart_price;
                        // Cache the updated total cart value
                        localStorage.setItem('totalCartValue', response.data.total_cart_price);
                    }
                }
            } else {
                // The request failed
                console.error(xhr.statusText);
            }
        };

        // Set up a callback function to handle errors
        xhr.onerror = () => {
            // Handle network errors
            console.error('Network error occurred');
        };

        // Prepare the data to be sent in the request body
        const data = `action=get_cart_total&security=${ajax.ajax_nonce}`;

        // Send the request with the data
        xhr.send(data);
    }

    updateFieldsVisibility() {
        var shippingOption = document.getElementById('shipping_option').value;
        var deliveryFloor = document.getElementById('delivery_floor').value;
        var submitButton = document.querySelector('.btn.btn--full.btn--red');
        // Show or hide pickup container based on the selected shipping option
        this.pickupContainer.classList.toggle('cart__top_pickup_container--hidden', shippingOption === 'local_pickup' || shippingOption === 'disabled');

        if (shippingOption === 'local_pickup') {
            submitButton.style.display = 'flex';
        } else if(shippingOption === 'paid_shipping') {
            submitButton.style.display = 'none';
            if (deliveryFloor === 'yes' || deliveryFloor === 'no') {
                submitButton.style.display = 'flex';
            } else {
                submitButton.style.display = 'none';
            }
        }else{
            submitButton.style.display = 'none';
        }


        // Show or hide pickup client and courier based on the selected shipping option
        if (shippingOption === 'local_pickup' || shippingOption === 'disabled') {
            this.shippingSpan.style.display = 'none';
            this.pickupClient.classList.add('cart__top_pickup--hidden');
            this.pickupCourier.classList.add('cart__top_pickup--hidden');
        } else {
            this.shippingSpan.style.display = 'block';
            if (deliveryFloor === 'yes') {
                // Show package-moving-floors if delivery floor is yes
                this.packageMovingFloors.style.display = 'block';
            } else {
                // Hide package-moving-floors if delivery floor is no
                this.packageMovingFloors.style.display = 'none';
            }

            // Show or hide pickup client and courier based on the selected delivery floor
            this.pickupClient.classList.toggle('cart__top_pickup--hidden', deliveryFloor === 'no');
            this.pickupCourier.classList.toggle('cart__top_pickup--hidden', deliveryFloor === 'yes');
        }
    }

    handleFormChange() {
        // Get values from the form elements
        const shippingOption = document.getElementById("shipping_option").value;
        const billing_city = document.getElementById("billing_city").value;
        const shippingStreet = document.getElementById("shipping_street").value;
        const shippingBuildingNumber = document.getElementById("shipping_building_number").value;
        const shippingApartmentNumber = document.getElementById("shipping_apartment_number").value;
        const delivery_floor = document.getElementById("delivery_floor").value;
        const packageMovingFloor = document.getElementById("package_moving_floor").value;

        // Store these values in the session using an AJAX request
        this.saveShippingSessionData({
            shippingOption,
            billing_city,
            shippingStreet,
            shippingBuildingNumber,
            shippingApartmentNumber,
            delivery_floor,
            packageMovingFloor,
        });
    }

    saveShippingSessionData(data) {
        // Create an XMLHttpRequest
        const xhr = new XMLHttpRequest();
        xhr.open("POST", ajax.ajax_url, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        // Construct the data string
        const dataString = "action=save_shipping_session" + "&security=" + ajax.security + "&shipping_option=" + encodeURIComponent(data.shippingOption) + "&billing_city=" + encodeURIComponent(data.billing_city) + "&shipping_street=" + encodeURIComponent(data.shippingStreet) + "&shipping_building_number=" + encodeURIComponent(data.shippingBuildingNumber) + "&shipping_apartment_number=" + encodeURIComponent(data.shippingApartmentNumber) + "&delivery_floor=" + data.delivery_floor + "&package_moving_floor=" + encodeURIComponent(data.packageMovingFloor);

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    this.makeAjaxCall();
                } else {
                    console.error("AJAX request failed with status:", xhr.status);
                }
            }
        };

        xhr.onerror = function () {
            console.error("AJAX request failed.");
        };

        // Send the data string
        xhr.send(dataString);
    }

    makeAjaxCall() {

        const shippingOption = document.getElementById('shipping_option');
        const deliveryFloorElement = document.getElementById('delivery_floor');
        const packageMovingFloor = document.getElementById('package_moving_floor') ?? false;
        const selectedFloorOption = packageMovingFloor.options[packageMovingFloor.selectedIndex] ?? false;
        const additionalFee = selectedFloorOption.dataset.fee || '0';
        const loadingDiv = document.querySelector('#loading');

        packageMovingFloor.disabled = true;
        // Add the "loading" and "spinner" classes to the loading div initially
        loadingDiv.classList.add('loading', 'spinner');

        // Create an XMLHttpRequest
        const xhr = new XMLHttpRequest();
        xhr.open("POST", ajax.ajax_url, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        // Construct the data string
        const dataString = "action=handle_delivery_floor&security=" + ajax.security + "&delivery_floor=" + encodeURIComponent(deliveryFloorElement.value) + "&fee=" + encodeURIComponent(additionalFee)+ "&floor=" + encodeURIComponent(selectedFloorOption.value) + "&shipping=" + encodeURIComponent(shippingOption.value);


        xhr.onreadystatechange = () => {

            if (xhr.readyState === XMLHttpRequest.DONE) {
                loadingDiv.classList.remove('loading', 'spinner');
                packageMovingFloor.disabled = false;
                if (xhr.status === 200) {

                    const response = JSON.parse(xhr.responseText);

                    // Handle the response, e.g., update the cart totals
                    if (response.success) {
                        this.checkTotalCartValueAfter()
                    }
                } else {
                    console.error("AJAX request failed with status:", xhr.status);
                }
            }
        };

        xhr.onerror = function () {
            window.reload();
            loadingDiv.classList.remove('loading', 'spinner');
            console.error("AJAX request failed.");
        };

        // Send the data string
        xhr.send(dataString);
    }

    checkTotalCartValueAfter() {
        // Create a new XMLHttpRequest object
        var xhr = new XMLHttpRequest();

        // Define the request type, URL, and set it to be asynchronous
        xhr.open('POST', ajax.ajax_url, true);

        // Set the request header for POST requests
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        // Set up a callback function to handle the response
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                // The request was successful
                var response = JSON.parse(xhr.responseText);

                // Handle success, e.g., update the cart totals
                if (response.success) {
                    // Find the corresponding cart item element using product_id
                    var totalCartPriceElement = document.querySelector('.cart__totals_sub dd');

                    if (totalCartPriceElement) {
                        totalCartPriceElement.innerHTML = response.data.total_cart_price;

                        // Cache the updated total cart value
                        localStorage.setItem('totalCartValue', response.data.total_cart_price);
                    }
                }
            } else {
                // The request failed
                console.error(xhr.statusText);
            }
        };

        // Set up a callback function to handle errors
        xhr.onerror = function () {
            // Handle network errors
            console.error('Network error occurred');
        };

        // Prepare the data to be sent in the request body
        var data = 'action=get_cart_total&security=' + ajax.ajax_nonce;

        // Send the request with the data
        xhr.send(data);
    }
}

export class WooCommerceAutofill {
    constructor() {
        this.fetchSessionData();
    }

    fetchSessionData() {
        fetch(ajax.ajax_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `action=save_shipping_session&security=${ajax.ajax_nonce}`,
        })
            .then(response => {
                console.log(response);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data && Object.keys(data).length > 0) {
                    // Item removed successfully, update the UI or take any necessary actions
                    document.querySelector('.woocommerce-input-wrapper #billing_city').value = data.billing_city || '';
                    document.querySelector('.woocommerce-input-wrapper #billing_address_1').value = `${data.shipping_street || ''}, ${data.shipping_building_number || ''}-${data.shipping_apartment_number || ''}`;
                    // Add more fields as needed
                } else {
                    console.error('Failed to fetch session data or the data is empty.');
                }
            })
            .catch(error => {
                console.error('Error during the session data request:', error);
            });
    }
}

