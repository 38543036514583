import {CartFieldsHandler, WooCommerceAutofill} from './actions'

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.woocommerce-cart')) {
        const disabledShippingOption = document.querySelector('.shipping-option-form option[value="disabled"]');
        const disabledMovingOption = document.querySelector('.delivery_floor-form option[value="disabled"]');

        // Disable the option
        if (disabledShippingOption) disabledShippingOption.disabled = true;
        if (disabledMovingOption) disabledMovingOption.disabled = true;
        new CartFieldsHandler();
    }
    // if (document.querySelector('.woocommerce-checkout')) {
    //     new WooCommerceAutofill();
    // }



});